import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { SecretKey } from './secret-key.interface';

@Component({
  selector: 'app-secret-key',
  templateUrl: './secret-key.component.html',
  styleUrls: ['./secret-key.component.scss']
})
export class SecretKeyComponent {

  constructor(private http: HttpClient,
    private alert: AlertService
  ) {
    this.getPlatformKey()
  }

  
  serviceItem!: SecretKey
  getPlatformKey() {
    this.http.get(`platformkey`).subscribe({
      next: (res: any) => {
        this.serviceItem = res.data;
      }
    })
  }

  updateKey() {
    this.alert.confirm('If you change the key, the system that uses the key will not be able to use it. Do you want to change it?').then((fm) => {
      if (fm.isConfirmed) {
        this.http.put(`platformkey`, null).subscribe({
          next: (res: any) => {
            this.getPlatformKey()
            this.alert.notify(res.message, 'success')
          }, error: (err) => {
            this.alert.notify(err.error.message, 'warning')
          }
        })

      }
    })
  }
}
