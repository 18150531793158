import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfig } from './configs/http.config';
import { ToastComponent } from './shareds/toast/toast.component';
import { LoadingComponent } from './shareds/loading/loading.component';
import { SimpleLayoutModule } from './layouts/simple-layout/simple-layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { UploadFileComponent } from './shareds/upload-file/upload-file.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { PackageComponent } from './pages/package/package.component';
import { PkDetailComponent } from './pages/package/pk-detail/pk-detail.component';
import { PaginationComponent } from './shareds/pagination/pagination.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { UiComponent } from './shareds/ui/ui.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { WebOriginsComponent } from './pages/web-origins/web-origins.component';
import { RestApiComponent } from './pages/rest-api/rest-api.component';
import { SettingComponent } from './pages/setting/setting.component';
import { SettingPaymentComponent } from './pages/setting-payment/setting-payment.component';
import { KeyComponent } from './pages/key/key.component';
import { SecretKeyComponent } from './pages/secret-key/secret-key.component';


function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://api-dev.legitid.org/auth/',
        realm: 'Avesta-Backoffice',
        clientId: 'dashboard',
        // clientId: 'bookingX',

      },
      initOptions: {
        onLoad: 'login-required',  // allowed values 'login-required', 'check-sso';
        // flow: "standard"          // allowed values 'standard', 'implicit', 'hybrid';
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    UploadFileComponent,
    PackageComponent,
    PkDetailComponent,
    PaginationComponent,
    UiComponent,
    ProfileComponent,
    WebOriginsComponent,
    RestApiComponent,
    SettingComponent,
    SettingPaymentComponent,
    KeyComponent,
    SecretKeyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
    ToastComponent,
    SimpleLayoutModule,
    KeycloakAngularModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    TableModule,
    ToastModule

  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfig, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
