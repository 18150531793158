import { Component } from '@angular/core';

@Component({
  selector: 'app-setting-payment',
  templateUrl: './setting-payment.component.html',
  styleUrls: ['./setting-payment.component.scss']
})
export class SettingPaymentComponent {

}
