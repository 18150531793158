import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { GetOrigin } from './web-origins.interface';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-web-origins',
  templateUrl: './web-origins.component.html',
  styleUrls: ['./web-origins.component.scss']
})
export class WebOriginsComponent {
  constructor(private http: HttpClient,
    private alert: AlertService
  ) {
    this.getOrigin();
  }

  originItems!: GetOrigin[]
  getOrigin() {
    this.http.get('origins').subscribe({
      next: (res: any) => {
        console.log(res);
        this.originItems = res.data
      }
    })
  }

  updateValue(item: GetOrigin, v: any) {
    item.Wg_name = v.value
  }

  addOrigin() {
    this.http.post(`origins/create`, null).subscribe({
      next: (res: any) => {
        this.alert.notify(res.message, 'success')
      }, complete: () => {
        this.getOrigin();
      }
    })
  }

  deleteOrigin(item: GetOrigin) {
    this.alert.confirm('', 'After deleting the domain, the APi cannot be used. Do you want to delete it?').then(fm => {
      if (fm.isConfirmed) this.http.delete(`origins/delete/${item.Wg_id}`).subscribe({
        next: (res: any) => {
          this.alert.notify(res.message, 'success')
        }, complete: () => {
          this.getOrigin();
        }
      })
    })

  }

  saveOrigin() {
    console.log(this.originItems);
    this.http.put(`origins/update`, this.originItems).subscribe({
      next: (res: any) => {
        this.alert.notify(res.message, 'success')
      }, complete: () => {
        this.getOrigin();
      }
    })
  }
}
