export const AppURL = {
    package: 'package',
    setting_payment: 'setting-payment',
    setting: 'setting',
    restapi: 'rest-api',
    weborigins: 'web-origins',
    ui: 'ui',
    profile: 'profile',
    systemkey: 'system-key',
    secretkey: 'secret-key',
};