<div class="container">
    <div class="row m-0 p-0 g-3">
        <div class="col-md-6 col-12">
            <div class="row">
                <div class="col-12">
                    <h3>Web Origins</h3>
                    <button class="btn btn-primary" (click)="addOrigin()">
                        Add Domain
                    </button>
                </div>
                <div class="col-12 mt-3" *ngFor="let og of originItems">
                    <div class="d-flex">
                        <input type="text" class="form-control w-100" [value]="og.Wg_name || ''"
                            [placeholder]="og.Wg_name || 'https://example.com'" (input)="updateValue(og,dm)" #dm>

                        <span class="material-symbols-outlined mt-2 ms-2 m-action" (click)="deleteOrigin(og)">
                            delete
                        </span>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <button class="btn btn-cancel" (click)="saveOrigin()">
                        Save Origin
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>