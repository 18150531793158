<div class="container">
    <div class="row p-0 m-0 g-3">
        <div class="col-md-4 col-12"> <button class="btn btn-cancel lg icon-start me-3 w-100" (click)="back()">
                <span class="material-symbols-outlined">
                    arrow_back
                </span>
                BACK
            </button></div>
        <div class="col-md-4 col-12"> <button class="btn btn-primary lg icon-start w-100" (click)="addPlan()">
                <span class="material-symbols-outlined">
                    add
                </span>
                Create Plan
            </button>
        </div>
        <div class="col-md-4 col-12 pt-2 text-end">
            <div class="flex">
                <span class="me-3"><b>Status System :</b></span>
                <button class="btn me-2"
                    [ngClass]="{'btn-primary':modelItem?.System_status == 1,'btn-cancel':modelItem?.System_status == 0}"
                    (click)="changeStatusSystem(1)"><b>ON</b></button>
                <button class="btn "
                    [ngClass]="{'btn-primary':modelItem?.System_status == 0,'btn-cancel':modelItem?.System_status == 1}"
                    (click)="changeStatusSystem(0)"><b>OFF</b></button>
            </div>
        </div>

    </div>
    <div class="row g-3 p-0 m-0">
        <div class="col-xl-4 col-lg-4 col-12 text-center m-action">
            <app-upload-file (FileUpload)="UploadFile($event)" [width]="1024" [height]="1024">
                <div class="box-item p-3">
                    <span class="material-symbols-outlined edit">
                        edit_square
                    </span>
                    <div class="box-image text-center ">
                        <img [src]="modelItem?.System_logo">
                    </div>
                    <p><b>{{modelItem?.System_name}}</b></p>
                    <span>1024 x 1024 </span>
                </div>
            </app-upload-file>
        </div>
        <div class="col-lg-8 col-12">
            <div class="row g-3">
                <div class="col-md-10 col-12">
                    <div class="row g-3 p-3" [formGroup]="formUpdateSystem">
                        <div class="box-item">
                            <div class="col-12 mb-3"> <label><b>System name</b></label>
                                <input type="text" class="form-control" placeholder="System Name"
                                    formControlName="System_name">
                            </div>
                            <div class="col-12 mb-3">
                                <label><b>System url</b></label>
                                <input type="text" class="form-control" placeholder="System Url"
                                    formControlName="System_url">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <button class="btn btn-primary sm w-100 h-100" (click)="UpdateSystem()">Update</button>
                </div>

            </div>
        </div>
    </div>
    <br>
    <div class="row p-0 m-0">
        <div class="col-12  box-sort">
            <button *ngFor="let cl of classItems" class="btn" [ngClass]="{'btn-sec':sortAction == cl.Class}"
                (click)="useSort(cl.Class)">
                <span>{{cl.Class}} Month</span>
            </button>
        </div>
    </div>
    <div class="row p-0 m-0">
        <div class="col-xl-4 col-lg-4 col-md-6 col-12 text-center" *ngFor="let pk of packageItem">
            <div class="shadow  p-3 mt-5 box-package">
                <h2>{{pk?.Package_name}}</h2>
                <hr>
                <h3>{{pk?.Package_price | number : '1.2-2'}} THB</h3>
                <p>{{pk?.Package_license}} License </p>
                <p>Each license is valid for {{pk.Package_type}} month.</p>
                <button class="btn btn-primary me-2 w-100 mb-3" (click)="ViewPlan(pk)">Update Plan</button>
                <button class="btn btn-cancel w-100" (click)="DeletePlan(pk)">Delete Plan</button>
            </div>
        </div>
    </div>
    <br><br><br>

    <p-dialog [header]="headerText" *ngIf="statusPlan" [(visible)]="statusPlan" [breakpoints]="{ '960px': '90vw' }"
        [style]="{ width: '50vw' }">
        <div class="row g-3" [formGroup]="formPlan">
            <div class="col-6">
                <p>How many months can it be used?</p>
                <input type="number" class="form-control" formControlName="Package_type">
            </div>
            <div class="col-6">
                <p>Package Name</p>
                <input type="text" class="form-control" formControlName="Package_name">
            </div>
            <div class="col-6">
                <p>How many licenses did you get?</p>
                <input type="number" class="form-control" formControlName="Package_license">
            </div>
            <div class="col-6">
                <p>Package Price</p>
                <input type="number" class="form-control" formControlName="Package_price">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-12">
                <button *ngIf="createPlan" class="btn btn-primary lg icon-start" (click)="CreatePlan()">
                    <span class="material-symbols-outlined">
                        add
                    </span>
                    Create Plan
                </button>
                <button *ngIf="updatePlan" class="btn btn-primary lg icon-start" (click)="UpdatePlan()">
                    <span class="material-symbols-outlined">
                        edit_square
                    </span>
                    Update Plan
                </button>
            </div>
        </div>
    </p-dialog>
</div>