<div class="container" *ngIf="serviceItem">
    <div class="row">
        <div class="col-lg-6 col-12">
            <h1>Service Secret Key Platform</h1>
            <br>
            <p>Service ID</p>
            <input type="text" class="form-control" disabled [(ngModel)]="serviceItem.ServiceId">
            <br>
            <p>Secret Key</p>
            <input type="text" class="form-control" disabled [(ngModel)]="serviceItem.SecretKey">
            <br>
            <button class="btn btn-primary" (click)="updateKey()">Change Key</button>
            <p class="text-danger">If you change the key, the system that uses the key will not be able to use it. Do you want to change it?</p>
        </div>
    </div>
</div>