import { Component } from '@angular/core';
import { BasketItems, CreatePackage, CreateSystem, Package, System, SystemList } from './package';
import { AppURL } from 'src/app/app.url';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConvertService } from 'src/app/services/convert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent {
  constructor(private router: Router,
    private http: HttpClient,
    private con: ConvertService) {
    if (localStorage.getItem('basket')) {
      let model: any = localStorage.getItem('basket')
      JSON.parse(model).forEach((f: BasketItems) => {
        this.actionOrders.push(f)
      })
    }
    this.getSystem()
  }
  actionOrders: any[] = [];
  package: any = [] = [
    { logo: '../assets/logo/POS_0.png', name: 'POS', url: '', status: false },
    { logo: '../assets/logo/Booking X_0.png', name: 'Booking', url: '', status: false },
    { logo: '../assets/logo/NLChat_0.png', name: 'NLChat', url: '', status: false },
    { logo: '../assets/logo/Biz soft_0.png', name: 'Bizsoft', url: '', status: false },
    { logo: '../assets/logo/SURVEY BOX_0.png', name: 'Survey', url: '', status: false },
    { logo: '../assets/logo/DeeAuction_0.png', name: 'DeAuction', url: '', status: false },
  ]

  SystemItems!: System[]
  getSystem() {
    this.http.get(`system`).subscribe({
      next: (res: any) => {
        this.SystemItems = res.data
        this.SystemItems.forEach(f => {
          if (f.System_logo) f.System_logo = environment.contentPackage + f.System_logo
        })
      }
    })
  }

  createStatus: boolean = false;
  createSystem() {
    this.createStatus = true;
  }

  systemItems!: CreateSystem
  viewLogo: string = ""
  updateFile(e: any) {
    // console.log(e.base64);

    this.viewLogo = e.base64
    this.systemItems = <CreateSystem>{
      System_Logo: e.file,
      System_Name: ''
    }
  }

  createSystemApi(name: any) {
    this.systemItems.System_Name = name.value
    this.http.post(`system/create`, this.con.paseFormData(this.systemItems)).subscribe({
      next: (res: any) => {
        this.getSystem();
      },
      complete: () => {
        this.createStatus = false;
      }
    })
  }

  viewPackage!: System;
  viewItem: boolean = false
  view(item: System) {
    this.viewPackage = item
    this.viewPackage.System_status = true;
    this.router.navigate(['/', AppURL.package, item.System_id])
  }

  update(v: any) {
    this.viewPackage.System_status = false;
  }



}

