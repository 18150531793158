import { Injectable } from '@angular/core';
import { Subject, Subscription, interval, timer } from 'rxjs';
import { AlertToast, AlertToastType } from '../interfaces/alert.interface';
import { Confirmation, ConfirmationService, MessageService } from 'primeng/api';
import { DateTimePickerOptions } from '../interfaces/event.interface';
import Swal from 'sweetalert2';

export const ALERT_TOAST_EVENT = new Subject<AlertToast>();
export const ALERT_LOADING_EVENT = new Subject<boolean>();

@Injectable({ providedIn: 'root' })
export class AlertService {

    // /** แจ้งเตือนธรรมดา */
    notify(message: string, severity: "success" | "info" | "warning" | "error" = "error") {
        return Swal.fire({
            position: "center",
            icon: severity,
            title: message,
            showConfirmButton: false,
            timer: 1500
        });
    }

    // /** การยืนยันความต้องการลบข้อมูล */
    confirm(header: string = 'Notification from the system', message: string = '', warn: string = '') {
        return Swal.fire({
            title: header,
            html: `<b>${message}</b> </br></br> <span class="text-danger">${warn}</span>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Confirm",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancel",

        })
    }


    // /** แจ้งเตือนจากระบบ dialog */
    dialog(message: string, header: string = 'Notification from the system', severity: "success" | "info" | "error" | "warning" = "warning") {
        return Swal.fire({
            icon: severity,
            title: header,
            text: message,
            // footer: '<a href="#">Why do I have this issue?</a>'
        });
    }

}
