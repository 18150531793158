import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BasketItems, Package, PackageHeader, PackageItems, PackageList, System, SystemViewId } from '../package';
import { AppURL } from 'src/app/app.url';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConvertService } from 'src/app/services/convert.service';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-pk-detail',
  templateUrl: './pk-detail.component.html',
  styleUrls: ['./pk-detail.component.scss']
})
export class PkDetailComponent {
  modelItem?: SystemViewId
  packageItem!: PackageItems[];
  sortAction: number = 1;
  packages: any = [
    {
      package_price: 1,
      package_name: 'ผู้ใช้ทั่วไป',
      users: 1,
      icon: 'person'
    },
    {
      package_price: 1,
      package_name: 'องค์กรขนาดเล็ก',
      users: 5,
      icon: 'store'
    },
    {
      package_price: 1,
      package_name: 'องค์กรขนาดกลาง',
      users: 10,
      icon: 'apartment'
    },
    {
      package_price: 1,
      package_name: 'องค์กรขนาดใหญ่',
      users: 20,
      icon: 'source_environment'
    },
  ]
  system_id: string = ""


  @Output('update') updateItem = new EventEmitter()
  constructor(private router: Router,
    private http: HttpClient,
    private ac: ActivatedRoute,
    private fb: FormBuilder,
    private con: ConvertService,
    private alert: AlertService) {
    this.system_id = this.ac.snapshot.params['systemid']
    if (this.system_id) {
      this.getSystemId()
      this.getPackageClass();
    }
  }
  orders: number = 0
  actionOrders: BasketItems[] = []
  actionLicense: PackageItems | undefined;
  useLicense(item: PackageItems) {
    this.actionLicense = item;
    this.licenseAmount = 1
    this.packageAmount = this.actionLicense.Package_license
    this.priceAmount = this.actionLicense.Package_price
  }

  getSystemId() {
    this.http.get(`system/${this.system_id}`).subscribe({
      next: (res: any) => {

        this.modelItem = res.data[0]
        this.formUpdateSystem.get('System_name')?.setValue(this.modelItem?.System_name)
        this.formUpdateSystem.get('System_url')?.setValue(this.modelItem?.System_url)
        if (this.modelItem?.System_logo) this.modelItem.System_logo = `${environment.contentPackage}/${this.modelItem.System_logo}`
      }
    })
  }

  classItems!: PackageHeader[]
  getPackageClass() {
    this.http.get(`package/headerview/${this.system_id}`).subscribe({
      next: (res: any) => {

        if (!res?.data) return
        this.classItems = res.data
        this.sortAction = this.classItems[0].Class
        this.getPackage();


      }
    })
  }



  getPackage() {
    this.http.get(`package/view/${this.system_id}/${this.sortAction}`).subscribe({
      next: (res: any) => {
        this.packageItem = res.data
        this.packageItem.forEach((f: PackageItems) => {
          if (f.Package_logo) f.Package_logo = environment.contentPackage + '/' + f.Package_logo
        })

      }
    })
  }

  changeStatusSystem(status: number) {
    if (this.modelItem?.System_status == status) return
    let text = 'Want to disable the system?'
    if (status == 1) text = 'Want to activate the system?'
    this.alert.confirm('', text).then(fm => {
      if (fm.isConfirmed) this.http.put(`system/update/status/${this.system_id}`, {
        system_status: status
      }).subscribe({
        next: () => {
          console.log(status);
          this.getSystemId()
        }
      })
    })


  }

  licenseAmount: number = 1;
  packageAmount: number = 0
  priceAmount: number = 0
  updateAmount(v: any) {
    if (!this.actionLicense) return
    this.licenseAmount = v.value
    this.packageAmount = this.actionLicense.Package_license * this.licenseAmount
    this.priceAmount = this.actionLicense.Package_price * this.licenseAmount
  }

  addBasket() {
    if (localStorage.getItem('basket')) localStorage.removeItem('basket')
    for (let i = 0; i < this.licenseAmount; i++) {
      let model: BasketItems = {
        systemId: this.modelItem?.System_id,
        packageId: this.actionLicense?.Package_id,
        system_name: this.modelItem?.System_name,
        package_name: this.actionLicense?.Package_name,
        package_price: this.actionLicense?.Package_price,
        license: this.actionLicense?.Package_license,
        package_type: this.actionLicense?.Package_type,
        price: this.actionLicense?.Package_price
      }
      this.actionOrders.push(model)
    }
    this.actionLicense = undefined
    localStorage.setItem('basket', JSON.stringify(this.actionOrders))
    console.log(this.actionOrders);

  }



  statusPlan: boolean = false;
  createPlan: boolean = false;
  updatePlan: boolean = false;
  headerText: string = ""
  addPlan() {
    this.clearAction()
    this.headerText = "Create Plan"
    this.statusPlan = true
    this.createPlan = true;
  }


  ViewPlan(item: any) {
    this.clearAction()
    this.headerText = "Update Plan"
    this.statusPlan = true
    this.updatePlan = true
    this.formPlan.patchValue(item)
  }

  clearAction() {
    this.formPlan.reset()
    this.statusPlan = false;
    this.updatePlan = false;
    this.createPlan = false;
  }


  formPlan: FormGroup = this.fb.group({
    Package_id: "",
    Package_type: "",
    Package_name: "",
    Package_license: "",
    Package_price: "",
    Package_logo: "",


  })
  CreatePlan() {
    this.http.post(`package/create/${this.system_id}`, this.con.paseFormData(this.formPlan.value)).subscribe({
      next: (res: any) => {
        this.alert.notify(res.message, 'success')
      }, complete: () => {
        this.clearAction()
        this.getPackageClass()
      }
    })
  }

  UpdatePlan() {
    this.formPlan.get('Package_price')?.setValue(parseFloat(this.formPlan.value['Package_price']))
    // console.log(this.formPlan.value);
    this.http.put(`package/update/${this.formPlan.value['Package_id']}`, this.con.paseFormData(this.formPlan.value)).subscribe({
      next: (res: any) => {
        this.alert.notify(res.message, 'success')
      }, complete: () => {
        this.clearAction()
        this.getPackageClass()
      }
    })
  }

  DeletePlan(item: PackageItems) {

    this.alert.confirm('Notification from the system', 'Want to delete a plan?').then(fm => {
      if (fm.isConfirmed) this.http.delete(`package/delete/${item.Package_id}`).subscribe({
        next: (res: any) => {
          this.alert.notify(res.message, 'success')
        }, complete: () => {
          this.clearAction()
          this.getPackageClass()
        }
      })
    })

  }


  formUpdateSystem: FormGroup = this.fb.group({
    System_name: "",
    System_url: ""

  })
  UpdateSystem() {
    this.http.put(`system/update/${this.system_id}`, this.formUpdateSystem.value).subscribe({
      next: () => {
        this.getSystemId();
      }
    })
  }

  UploadFile(e: any) {
    this.UpdateLogo(e.file)
  }

  UpdateLogo(file: File) {
    // https://backoffice.legitid.org/dashboard/api/
    this.http.put(`system/logo/${this.system_id}`, this.con.paseFormData({ System_logo: file })).subscribe({
      next: (res: any) => {
        this.getSystemId();
        this.alert.notify(res.message, 'success')
      }, error: (err) => {
        this.alert.notify(err.error.message, 'error')
      }
    })
  }


  useSort(sort: number) {
    this.sortAction = sort
    this.getPackage()
  }

  back() {
    this.router.navigate(['/', AppURL.package])
  }
}
