import { NgModule } from '@angular/core';

import { AppURL } from './app.url';
import { PackageComponent } from './pages/package/package.component';
import { UiComponent } from './shareds/ui/ui.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PkDetailComponent } from './pages/package/pk-detail/pk-detail.component';
import { RouterModule, Routes } from '@angular/router';
import { WebOriginsComponent } from './pages/web-origins/web-origins.component';
import { SettingPaymentComponent } from './pages/setting-payment/setting-payment.component';
import { SettingComponent } from './pages/setting/setting.component';
import { RestApiComponent } from './pages/rest-api/rest-api.component';
import { KeyComponent } from './pages/key/key.component';
import { SecretKeyComponent } from './pages/secret-key/secret-key.component';


const routes: Routes = [
  {
    path: AppURL.package, children: [
      { path: '', component: PackageComponent },
      { path: ':systemid', component: PkDetailComponent },
    ]
  },



  {
    path: AppURL.profile, children: [
      { path: '', component: ProfileComponent },
    ]
  },
  {
    path: AppURL.weborigins, children: [
      { path: '', component: WebOriginsComponent },
    ]
  },
  {
    path: AppURL.setting_payment, children: [
      { path: '', component: SettingPaymentComponent },
    ]
  },
  {
    path: AppURL.setting, children: [
      { path: '', component: SettingComponent },
    ]
  },
  {
    path: AppURL.systemkey, children: [
      { path: '', component: KeyComponent },
    ]
  },
  {
    path: AppURL.restapi, children: [
      { path: '', component: RestApiComponent },
    ]
  },
  {
    path: AppURL.secretkey, children: [
      { path: '', component: SecretKeyComponent },
    ]
  },

  {
    path: AppURL.ui, children: [
      { path: '', component: UiComponent },
    ]
  },
  { path: '**', loadComponent: () => import('./pages/notfound/notfound.component') }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
