<div class="container" *ngIf="!viewPackage?.System_status">
    <div class="row">
        <div class="col-6">
            <h1>PACKAGE</h1>
            <button class="btn btn-primary lg icon-start" (click)="createSystem()">
                <span class="material-symbols-outlined">
                    add
                </span>
                Create System</button>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-12 text-center" *ngFor="let sys of SystemItems">
            <div class="shadow  p-3 mt-3 box-package" (click)="view(sys)">
                <div class="box-image text-center ">
                    <img [src]="sys.System_logo">
                </div>
                <p><b>{{sys.System_name}}</b></p>
            </div>
        </div>
    </div>
    <br><br><br>
</div>


<p-dialog header="Create System" *ngIf="createStatus" [(visible)]="createStatus" [breakpoints]="{ '960px': '90vw' }"
    [style]="{ width: '50vw' }">
    <div class="row justify-content-center">
        <div class="col-4 text-center">
            <app-upload-file (FileUpload)="updateFile($event)">
                <div class="box-logo m-action" *ngIf="!viewLogo">
                    <p>Logo System</p>
                </div>
                <img width="100%" *ngIf="viewLogo" [src]="viewLogo" alt="">
            </app-upload-file>
        </div>
    </div>
    <br>
    <div class="row justify-content-center">
        <div class="col-6">
            <p>System Name</p>
            <input type="text" class="form-control" #systemname>
        </div>
    </div>
    <br>
    <div class="row justify-content-center">
        <div class="col-6 text-center">
            <button class="btn btn-primary lg icon-start" (click)="createSystemApi(systemname)">
                <span class="material-symbols-outlined">
                    add
                </span>
                Create System
            </button>
        </div>
    </div>
</p-dialog>